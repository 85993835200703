table {
    width: 100vh;
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.codes__container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.valid-codes-table,
.valid-codes-table {
    width: 40%;
}

.info-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    min-height: 25%;
    height: 25%;
    max-height: 25%;
}


.info-header h2 {
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.container__infotable {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 4px 9px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 90%;
    height: 30%;
    max-width: 90%;
    max-height: 30%;
}

.code-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(45, 51, 68);
    border-radius: 10px;
    height: 100%;
    width: 90%;
    font-weight: bold;
    list-style: none;
    padding: 0;
    margin-bottom: 5%;
    overflow: auto;
}

.code-info li {
    color: rgba(255, 255, 255, 0.699);
}

span {
    margin-bottom: 10%;
}

.container__infotable button {
    height: 50%;
    max-height: 50%;
    width: 25%;
    background: rgb(64, 80, 131);
    font-size: 0.8rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: linear 0.5s;
}

button:disabled {
    color: #595959;
    background-color: #3e3e3e;
    cursor: not-allowed;
}

button:disabled:hover {
    background-color: #727272;
}

button:hover {
    background-color: #aab7ff;
    color: #566cff;
    text-shadow: rgb(255, 255, 255) 0px 0px 7px;
}

button span {
    font-size: inherit;
    color: inherit;
}

.container__infotable {
    background: #0a0029;
}
.container__login div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
}

.login-btn {
    background-color: #4285f4;
    color: white;
    border-radius: 10px;
    font-size: 5px;
    height: 70%;
    width: 70%;
    max-width: 70%;
    max-height: 70%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #357ae8;
}

.container__login {
    background-color: #000C29;
}

@media (max-width : 1000px) {
    .container__login {
        box-shadow: none;
        background-color: #ffffff00;
        margin-bottom: 5%;
    }
}
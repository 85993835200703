.manual {
    width: 90%;
    height: 50%;
    max-width: 90%;
    max-height: 50%;
    border-radius: 10px;
    background-color: rgb(64, 80, 131);
    box-shadow: 4px 9px 10px 0 rgba(0, 0, 0, 0.3);
    overflow-y: auto;
}

.manual h1 {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    margin-top: 5%;
    text-align: center;
}

.manual .comments p {
    font-size: 0.85rem;
    color: rgb(255, 255, 255);
    margin: 5%;
    text-align: justify;
    margin-top: 10%;
}

.manual .comments svg, .manual svg {
    margin-right: 5%;
    width: 10%;
    height: 10%;
    max-width: 10%;
    max-height: 10%;
    color: rgb(255, 255, 255);
}

.manual {
    font-family: Spiegel, "Arial Narrow", Arial, sans-serif;
    letter-spacing: 0.05em;
}

.global-caniao {
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, rgba(4, 0, 21, .255) 0, rgba(251, 251, 251, .118) 100%);
    color: #fff;
    padding: 15px;
    letter-spacing: 2px;
    border-radius: 10px;
    margin-top: 10%;
    margin-bottom: 10%;
    font-size: 1rem;
    border: 1px solid #9b9b9b;
    text-decoration: none;
}

.comments, .global-caniao, .suport {
    width: 90%;
    margin-left: 5%;
}

.comments {
    margin-top: 10%;
}

.suport {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, rgba(4, 0, 21, .2) 0, rgba(251, 251, 251, .05) 100%);
    border-radius: 15px;
    border: 1px solid rgba(155, 155, 155, .26);
    text-align: center;
    height: 25%;
    margin-top: 10%;
}

.btn-suport,
.btn-share {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 0.95rem;
    color: #fff;
}

.btn-suport {
    width: 70%;
    margin-right: 7%;
    margin-bottom: 5%;
}

.btn-share:hover {
    background-color: rgba(39, 175, 26, 0.85);
}

.btn-share svg,
.btn-suport svg {
    width: 30px;
    height: 30px;
    max-width: 30px;
    max-height: 30px;
    color: #f2f2f2;
}

.btn-share {
    border-radius: 10px;
    background-color: rgba(49, 211, 33, .85);
    width: 70%;
    height: 40%;
}

.manual .comments p svg {
    color: rgb(98,211,252);
}

.manual {
    background: linear-gradient(to bottom,#0a0029, #000C29, rgb(32,29,67));
}

@media (max-width : 1000px) {
    .manual {
        margin-bottom: 5%;
    }

    .btn-share, .btn-suport {
        font-size: 0.85rem;
    }

    .btn-share {
        margin-bottom: 5%;
    }

    .btn-suport {
        margin-top: 2%;
    }

    .btn-share svg, .btn-suport svg {
        width: 12%;
    }

    .manual .comments p {
        font-size: 0.8rem;
    }
}

/* 
#2E3847
#000C29
#F0F3F7 
*/
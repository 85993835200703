@import url("./fonts/TungstenNarrow/style.css");

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(64, 62, 83);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(138, 143, 155);
}

tbody::-webkit-scrollbar {
    width: 10px;
}

/* tbody::-webkit-scrollbar-thumb {
    background: rgb(76, 153, 242);
} */

/* tbody::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgb(64,62,83);
} */

/* tbody::-webkit-scrollbar-track:hover {
    background: rgb(64,62,83);
} */

/* .title {
    font-family: Tungsten, Impact, sans-serif;
    font: Muli, Tungsten;
    font-size: 3rem;
    color: #f9f9f9;
    text-shadow: #d8ffdba2 0px 0px 7px;
    margin: 0;
    scroll-behavior: smooth;
} */

.title {
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 2%;
    width: 25%;
    height: 100%;
}

body {
    color: #F0F3F7;
}

.ab {
    color: #292929;
    background-color: #111;
    border-bottom: 2px solid rgba(51, 51, 51, .25);
    font-family: "FF Mark W05", Arial, sans-serif;
    color: rgb(32, 29, 67);
    color: rgb(77, 71, 187);

    color: rgb(24, 13, 64);
    color: rgb(36, 33, 80);


    color: rgb(98, 211, 252);
    color: rgb(19, 19, 76);
    color: rgb(39, 39, 55);
    /* cabeçalho */
    color: rgb(36, 35, 51);
    /* border */
    color: rgb(44, 43, 60);
    /* interior */

    color: rgb(138, 143, 155);
    /* scroll */
    color: rgb(64, 62, 83);
    /* scroll bg */

    color: rgb(123, 065, 242);
    color: rgb(151, 069, 249);
    color: rgb(068, 229, 236);
    color: rgb(078, 095, 215);
}

.container__user,
.container__info {
    background-color: #020659;
    background-color: rgb(24, 13, 64);
}

table,
.container__fulltable {
    background-color: #020659;
    background-color: rgb(24, 13, 64);
}

.container__form {
    background-color: #000C29;
    background: linear-gradient(to bottom, #0a0029, #000C29, rgb(32, 29, 67));
}

body {
    background: linear-gradient(to bottom right, rgb(24, 13, 64), rgb(77, 71, 187), rgb(84, 33, 147), rgb(24, 13, 64));
}

.Design-gráfico-1-hex {
    color: #020873;
}

.Design-gráfico-2-hex {
    color: #020659;
}

.Design-gráfico-3-hex {
    color: #49F2E1;
}

.Design-gráfico-4-hex {
    color: #F2A71B;
}

.Design-gráfico-5-hex {
    color: #F25C05;
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.container__login {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: rgb(64, 80, 131);
    box-shadow: 4px 9px 10px 0 rgba(0, 0, 0, 0.3);
    width: 90%;
    height: 8%;
    max-height: 8%;
}

.container__user {
    width: 20%;
    max-width: 20%;
    height: 98vh;
    max-height: 98vh;
    box-shadow: 4px 9px 10px 0 rgba(0, 0, 0, 0.3);
}

.container__main {
    display: flex;
    width: 80%;
}

.container__app {
    display: flex;
}

.container__user,
.container__main,
.container__info {
    text-align: center;
    font-size: 1em;
    font-weight: normal;
    border-radius: 20px;
}

.container__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.container__info {
    box-shadow: 0 13px 9px -4px rgba(0, 0, 0, 0.3);
}

.container__user,
.container__info,
main {
    margin: 0.5%;
}

.container__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.container__fulltable {
    width: 100%;
    height: 75%;
    max-width: 100%;
    max-height: 75%;
    border-radius: 10px;
    box-shadow: -4px 9px 13px 0 rgba(0, 0, 0, 0.3);
}

main {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 75%;
    border-radius: 20px;
    height: 98vh;
    max-height: 98vh;
    /* background-color: rgb(255, 71, 71); */
}

.container__form {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    box-shadow: -4px 9px 13px 0 rgba(5, 18, 54, .4);
    flex-shrink: 0;
    height: 25%;
    width: 90%;
    z-index: 1;
}

.container__info {
    background-color: #495CD9;
    height: 98vh;
    max-height: 98vh;
    width: 25%;
    max-width: 25%;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 33%;
    max-width: 33%;
}

.divisor {
    width: 33%;
    max-width: 33%;
}

input,
select {
    border: 1px solid rgb(64, 80, 131);
    background: rgb(64, 80, 131);
    text-align: center;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    font-size: medium;
    font-weight: bold;
    width: 75%;
    height: 30px;
    transition: linear 0.5s;
    cursor: pointer;
}

input::placeholder {
    color: #b4b4b4;
}

input:focus,
select:focus {
    outline: none;
    border: 1.5px solid #5e71ee;
    box-shadow: 0 0 1px #495CD9,
        0 0 1px #495CD9,
        0 0 5px #495CD9,
        0 0 10px #495CD9;
}

/* label {
    font-size: 1rem;
    padding: 12px 12px 12px 0;
}
 */

.btn-get-codes {
    padding: 2%;
    height: 20%;
    width: 100%;
    font-size: 1.5rem;
    background: linear-gradient(to bottom right,rgb(123,065,242), rgb(078,095,215),rgb(068,229,236));
    box-shadow: 0 0 20px 0 rgb(078,095,215);
    color: #f2f2f2;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: linear 0.5s;
}

aaaaa {
    color: rgb(123,065,242);
    color: rgb(151,069,249);
    color: rgb(068,229,236);
    color: rgb(078,095,215);
}


button:disabled {
    color: #595959;
    background: #3e3e3e;
    box-shadow: none;
    cursor: not-allowed;
}

button:disabled:hover {
    background-color: #727272;
}

button:hover {
    background: #aab7ff;
    color: #566cff;
    box-shadow: 0 4px 14px 0 rgb(078,095,215);
    text-shadow: rgb(255, 255, 255) 0px 0px 7px;
    transition: linear 0.5s;
}

button span {
    font-size: inherit;
    color: inherit;
}

@media (max-width : 1000px) {

    .container__app {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container__user {
        margin-top: 5%;
        width: 90%;
        max-width: 90%;
        height: 100%;
        max-height: 100%;
    }

    .container__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10%;
        width: 90%;
    }

    main {
        width: 100%;
    }

    .container__info {
        margin-top: 30%;
        width: 100%;
        max-width: 100%;
    }

    .container__form .title {
        width: 45%;
        height: 90%;
    }

    .container__form .divisor {
        width: 0;
        height: 0;
    }

    input {
        width: 70%;
    }

    input, select {
        font-size: small;
    }

}
.container__table {
    position: relative;
    top: -23%;
}

.filter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 43px;
    width: 100%;
    position: relative;
    top: 200px;
}

.filter .btn-copy {
    width: 10%;
    background: rgb(64, 80, 131);
    transition: linear 0.5s;
    cursor: pointer;
}


#cityFilter, #daysFilter {
    width: 30%;
}

#cityFilter:hover, #daysFilter:hover  {
    background-color: #aab7ff;
    color: #566cff;
    box-shadow: 0 2px 7px 0 #566cff;
    text-shadow: rgb(255, 255, 255) 0px 0px 7px;
    cursor: pointer;
}

table {
    background: #495CD9;
    border-radius: 10px;
    /* box-shadow: -4px 9px 13px 0 rgba(3, 107, 82, 0.3); */
    padding-top: 250px;
    height: 70%;
    max-height: 70%;
    width: 100%;
    max-width: 100%;
}

tr {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

th {
    color: white;
    font-family: Spiegel, "Arial Narrow", Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    height: 26.6px;
    line-height: 13px;
    text-align: center;
    width: 31.5%;
}

td {
    color: rgb(98,211,252);
    font-family: Spiegel, "Arial Narrow", Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    height: 26.6px;
    line-height: 13px;
    text-align: center;
    width: 27%;
}

tbody {
    display: block;
    margin-left: 9%;
    margin-right: 9%;
}

thead {
    display: block;
    margin-left: 9%;
    margin-right: 9%;
}

tbody {
    overflow-y: scroll;
    height: 50vh;
}

.btn-copy {
    height: 100%;
    max-height: 100%;
    width: 25%;
    font-size: 0.8rem;
    background-color: #495CD9;
    color: rgb(255, 255, 255);
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-copy:hover {
    background-color: #aab7ff;
    color: #566cff;
    text-shadow: rgb(255, 255, 255) 0px 0px 7px;
}

/* table button {
    all: unset;
    cursor: pointer;
}

table button svg {
    height: 18px;
    width: 18px;
}

table button:nth-child(1) svg {
    margin-right: 10px;
}

table button:nth-child(2) svg {
    color: rgba(223, 60, 109, 1);
} */

@media (max-width : 1000px) {

    td {
        font-size: 10px;
    }

}
.container__logs {
    background-color: rgb(64, 80, 131);
    border-radius: 10px;
    width: 90%;
    height: 30%;
    max-width: 90%;
    max-height: 30%;
    box-shadow: 4px 9px 10px 0 rgba(0, 0, 0, 0.3);
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-left: 5%;
    width: 90%;
    height: 75%;
    background-color: rgb(45, 51, 68);
    border-radius: 10px;
    font-family: 'Courier New', Courier, monospace;
}

.container__logs svg {
    color: white;
    width: 5%;
    height: 5%;
    cursor: pointer;
    margin-right: 6%;
    margin-left: 1%;
}

.container__logs h1 {
    font-size: 1rem;
    color: white;
    text-align: start;
    padding-top: 7%;
    margin-left: 5%;
}

.info p {
    font-size: 1rem;
}

.info svg {
    color: white;
    background-color: #5383FF;
    width: 25%;
    height: 30%;
    cursor: pointer;
}

.container__logs {
    background-color: #0a0029;
}
.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-family: Spiegel, "Arial Narrow", Arial, sans-serif;
    letter-spacing: 0.05em;
    width: 90%;
    height: 30%;
    max-width: 90%;
    max-height: 30%;
    border-radius: 10px;
    box-shadow: 4px 9px 10px 0 rgba(0, 0, 0, 0.3);
}

.container__user h1 {
    font-family: Spiegel, "Arial Narrow", Arial, sans-serif;
    letter-spacing: 0.05em;
    font-size: 2rem;
    color: #f6f6f7;
    margin: 0;
}

.user p {
    display: flex;
    font-size: 1rem;
    color: #f6f6f7;
    align-items: center;
}

.user svg {
    color: #f6f6f7;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 5px;
}

.user img {
    border-radius: 25%;
    width: 35%;
    height: 35%;
}

#user-released, #user-released svg {
    color: rgb(17, 224, 17);
}

#user-no-access, #user-no-access svg{
    color: rgb(238, 142, 32);
}

#user-no-access svg {
    margin-left: 5%;
}

.user .btn-share {
    height:30%;
    width: 50%;
}

.user {
    background-color: #000C29;
    background: linear-gradient(to bottom, #000C29, #0a0029);
}

@media (max-width : 1000px) {
    .user {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .user h1 {
        margin-top: 5%;
    }

    .user p {
        font-size: 0.8rem;
        margin-bottom: 5%;
    }

    .user img {
        margin-top: 2%;
        margin-bottom: 2%;
        width: 30%;
        height: 30%;
    }
}